<template>
    <div class="checkbox-input">
        <div class="checkbox-input__input">
            <input
                v-bind="$attrs"
                :id="id"
                ref="input"
                :checked="modelValue"
                type="checkbox"
                class="input__box"
                :class="invalid ? 'ring-2 ring-red-500' : ''"
                @input="emitter"
            />
            <label class="input__label" :for="id">
                <slot></slot>
            </label>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'InputCheckbox',
    props: {
        modelValue: Boolean,
        label: String,
        id: String,
        invalid: Boolean,
    },
    methods: {
        setValue(value: boolean) {
            ;(this.$refs.input as HTMLInputElement).checked = value

            this.$emit('update:modelValue', value)
        },
        emitter(e: any) {
            this.$emit('update:modelValue', (e.target as HTMLInputElement).checked)
        },
    },
})
</script>

<style lang="postcss" scoped>
.checkbox-input {
    &__input {
        @apply flex space-x-2;
        & .input__box {
            @apply block h-5 w-5 cursor-pointer rounded border-gray-300 text-site-primary checked:border-site-primary checked:ring-site-primary disabled:border-gray-300 disabled:bg-gray-400 disabled:hover:bg-gray-400;
        }
        & .input__label {
            @apply text-xs text-gray-800 sm:text-sm;
            a {
                @apply text-site-primary underline;
            }
        }
    }
}
</style>
